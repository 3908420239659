import { TitleDivider } from 'components/TitleDivider'
import { VideoPlayer } from 'components/VideoPlayer'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section, { SECTION_VARIANTS } from 'components/Section'
import React from 'react'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Typography } from 'components/Contentful'
import { StatGrid } from 'components/StatGrid'

function AboutUsVideo({ content }) {
  const { eyebrow, title, subtitle, disclaimer, videoPlayer, grid, config } =
    useMemoizedContentGetter(content, [
      'eyebrow',
      'title',
      'subtitle',
      'disclaimer',
      'videoPlayer',
      'grid',
      'config',
    ])

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const variant = {
    title: isMobile ? 'headingMd' : 'displayLg',
    titleFontWeight: 'medium',
    subtitle: isMobile ? 'bodySm' : 'displaySm',
    subtitleFontWeight: 'regular',
  }

  const variantBg = config?.jsonContent?.sectionVariant ?? SECTION_VARIANTS.bgPrimaryHighlightBlue

  return (
    <Section
      className={
        // styles['section']
        'overflow-hidden px-0 py-12 lg:px-0 lg:py-16'
      }
      variant={variantBg}
      data-testid="AboutUsVideo_section"
    >
      <TitleDivider
        eyebrowBlack={variantBg !== SECTION_VARIANTS.bgBlue100}
        title={title}
        eyebrow={eyebrow}
        subtitle={grid ? null : subtitle}
        isMobile={isMobile}
        variants={variant}
      />
      <VideoPlayer content={videoPlayer} aspectRatio={16 / 9} />
      {subtitle && (
        <Typography
          data-testid="title-divider-subtitle"
          className={
            // styles['subtitle']
            'flex-col max-w-[808px] m-auto pt-12'
          }
          content={subtitle.textContent}
          variant={isMobile ? 'bodySm' : 'bodyLg'}
        />
      )}
      {grid && <StatGrid grid={grid} />}
      {disclaimer && (
        <Typography
          data-testid="title-divider-disclaimer"
          className={
            // styles['disclaimer']
            'max-w-[808px] text-center text-[#42546b] m-auto pt-12'
          }
          content={disclaimer.textContent}
          variant="bodyXs"
        />
      )}
    </Section>
  )
}
export { AboutUsVideo }
export default AboutUsVideo
